import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Button, ProgressBar, Form } from "react-bootstrap";
import axios from "axios";

const UploadImg = ({ event }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const [alertMessage, setAlertMessage] = useState({success: false, message: ""});
  
  /** Dropzone callback to handle the files dropped */
  const onDrop = useCallback((acceptedFiles) => {
    const totalSizeInBytes = acceptedFiles.reduce(
      (acc, file) => acc + file.size,
      0
    );
    setSelectedFiles(acceptedFiles);
    setTotalSize((totalSizeInBytes / (1024 * 1024)).toFixed(2)); // Convert bytes to MB
    setUploadProgress(0);
    setAlertMessage({success: false, message: ""});

  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
    },
    multiple: true,
  });
  

  const handleFileSelect = (event) => {
    const files = event.target.files;
    const totalSizeInBytes = Array.from(files).reduce(
      (acc, file) => acc + file.size,
      0
    );
    setSelectedFiles(files);
    setTotalSize((totalSizeInBytes / (1024 * 1024)).toFixed(2));
  };

  /** upload the selected files and update the progress bar */
  const handleUpload = async () => {
    if (selectedFiles.length === 0) {
      alert("Please select one or more images to upload.");
      return;
    }

    // init the progress bar
    setUploadProgress(1);

    const formData = new FormData();
    Array.from(selectedFiles).forEach((file) => {
      formData.append("images", file);
    });

    const uploadId = `upload_${Date.now()}`; // Generate a unique ID for this upload
  
    try {

       // Start WebSocket connection
      const websocket = new WebSocket(`${process.env.REACT_APP_MODEL_ENDPOINT_WEBSOCKET}/ws/upload-progress/${uploadId}`);

      websocket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.progress !== undefined) {
          setUploadProgress(data.progress);
        } 
        if(data.progress === 100) {
          setAlertMessage({success: true, message: "Images uploaded successfully."});
          websocket.close();
        }
      };

      websocket.onclose = () => {
        // console.log("WebSocket connection closed");
      };

      // Upload the image with a unique upload ID
      const response = axios.post(
        `${process.env.REACT_APP_MODEL_ENDPOINT}/images?eid=${event.id}&upload_id=${uploadId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
  
      // if (response.status === 200) {
      //   setUploadProgress(100);
      //   let alertMessage = response.data.msg;
      //   if(response.data.existing.length > 0) {
      //     alertMessage  = "Existing images: " + response.data.existing.length + "<br>" + alertMessage;
      //   }


      // } else {
      //   // alert("Failed to upload images.");
      //   setAlertMessage({success: false, message: "Failed to upload images."});
      // }
    } catch (error) {
      console.error("Error uploading images:", error);
      // alert("An error occurred while uploading images.");
      setAlertMessage({success: false, message: "An error occurred while uploading images."});
      setUploadProgress(0);
    } finally {
    }
  };

  return (
    <div className="upload-container">
      <h2>Upload Images</h2>

      {/* Drag and Drop Zone */}
      <div
        {...getRootProps()}
        className={`dropzone p-3 mb-3 border rounded bg-light ${
          isDragActive ? "border-primary" : "border-secondary"
        }`}
        style={{
          borderStyle: "dashed",
          cursor: "pointer",
          height: "400px",
          height: "400px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here...</p>
        ) : (
          <p>
            Drag & drop some images here, or click to select files (PNG, JPEG,
            JPG)
          </p>
        )}
      </div>

      {uploadProgress > 0 && (
        <ProgressBar
          now={uploadProgress}
          label={`${uploadProgress}%`}
          className="mb-3"
        />
      )}

      {selectedFiles.length > 0 && (
        <>
          <div className="mb-3">
            <p>
              Total size: {totalSize} MB ({selectedFiles.length} file
              {selectedFiles.length > 1 ? "s" : ""})
            </p>
          </div>

          {/* uploading Spinner */}
          {uploadProgress > 0 && uploadProgress < 100 && (
            <div className="text-center">
              <span className="spinner-grow spinner-grow-sm text-primary" role="status" aria-hidden="true"></span>
              <span className="ms-2">Uploading...</span>
            </div>
          )}

          {uploadProgress == 0 && <Button
            variant="success"
            onClick={handleUpload}
          >Upload Selected Images</Button>}
        </>
      )}

      {/* alert success / fail message */}
      {alertMessage.message && (
        <div className={`alert ${alertMessage.success ? "alert-success" : "alert-danger"} mt-3`} role="alert"
        dangerouslySetInnerHTML={{ __html: alertMessage.message }}>
        </div>
      )}
    </div>
  );
};

export default UploadImg;
