// src/components/ImageGallery.js
import React from "react";
import { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import Lightbox from "yet-another-react-lightbox";
import { Gallery } from "react-grid-gallery";
import "yet-another-react-lightbox/styles.css";
import { AiOutlineDownload, AiOutlineDelete } from "react-icons/ai";
import styles from './style/Gallery.module.css'; // Import CSS Module


const ImageGallery = ({ event_id, images, show_delete , setImages}) => {
  // console.log(images);
  // const [galleryImages, setImages] = useState(initialImages);
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedImages, setSelectedImages] = useState([]);
  const [containerWidth, setContainerWidth] = useState(window.innerWidth);

  // Update the width dynamically on resize
  useEffect(() => {
    const handleResize = () => setContainerWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // Keep galleryImages in sync with images if they change in the parent
    setImages(images);
  }, [images]);

  {
  }

  /**
   * return the image endpoint from his name
   * @param {string} img
   * @param {boolean} mignature
   * @returns {string}
   * */
  const img_url = (img, mignature = false) => {
    return (
      `${process.env.REACT_APP_MODEL_ENDPOINT}/images/${img}?eid=${event_id}` +
      (mignature ? "&miniature=true" : "")
    );
  };

  const handleImageClick = (index) => {
    setSelectedIndex(index);
    setOpen(true);
  };

  const handleImageSelect = (index) => {
    const image = images[index];
    const isSelected = selectedImages.includes(image);

    if (isSelected) {
      // Remove the image from the selected list
      setSelectedImages(selectedImages.filter((img) => img !== image));
    } else {
      // Add the image to the selected list
      setSelectedImages([...selectedImages, image]);
    }
  };

  // Handle image download
  const handleDownloadImage = async (image_url) => {
    try {
      const response = await fetch(image_url, {
        method: "GET",
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = blobUrl;
      // get basename of the image url and remove all get parameters of the url
      link.download = image_url.split("?")[0].split("/").pop();

      document.body.appendChild(link); // Append link to the body
      link.click(); // Simulate click to trigger download
      document.body.removeChild(link); // Clean up the DOM by removing the link after download

      // Revoke the blob URL after download to free up memory
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Error downloading the image:", error);
    }
  };

  const handleDeleteImage = async (image) => {
    let image_url = img_url(image.name);
    try {
      const response = await fetch(image_url, {
        method: "DELETE",
      });
      setImages((prevImages) => prevImages.filter((img) => img !== image));
      setSelectedImages([]);
      return response;
    } catch (error) {
      console.error("Error deleting the image:", error);
      return false;
    }
  };

  const onDownloadSelected = () => {
    selectedImages.forEach((image) => {
      let url = img_url(image.name);
      handleDownloadImage(url);
    });
  };

  const onDeleteSelected = () => {
    if (
      !window.confirm(
        `Please confirm to delete the ${selectedImages.length} selected images?`
      )
    )
      return;
    selectedImages.forEach((image) => {
      handleDeleteImage(image);
    });
  };

  const deleteAll = async () => {
    if (!window.confirm("Are you sure you want to Delete all images?")) return;
    let url = `${process.env.REACT_APP_MODEL_ENDPOINT}/images?eid=${event_id}`;
    try {
      const response = await fetch(url, {
        method: "DELETE",
      });
      setImages([]);
      setSelectedImages([]);
      return response;
    } catch (error) {
      console.error("Error deleting the image:", error);
      return false;
    }
  };

  // todo: if more than 10 images, get zip file
  const downloadAll = () => {
    if (!window.confirm("Are you sure you want to download all images?"))
      return;
    images.forEach((image) => {
      let url = img_url(image.name);
      handleDownloadImage(url);
    });
  };

  const randHeight = () => {
    return Math.floor(Math.random() * 100) + 200;
  }

  // Helper function to get dynamic row height
  const getDynamicRowHeight = () => {
    if (window.innerWidth < 600) return 150; // Smaller height for small screens
    if (window.innerWidth < 1200) return 200; // Medium height for tablets
    return 300; // Default for larger screens
  };

    // react-gallery images
    const IMAGES = images
    .map((image) => ({
      original: img_url(image.name),
      src: img_url(image.name, true),
      isSelected: selectedImages.includes(image),
      width: image.width,
      height: image.height,
      caption: image.name,
      customOverlay: (
        <div className="custom-overlay__caption">
          <div></div>
          {image.tags &&
            image.tags.map((t, index) => (
              <div key={index} className="custom-overlay__tag">
                {t.title}
              </div>
            ))}
        </div>
      ),
    }));

    // const IMAGES: Image[] = [];




  return (
    <div className="container bg-secondary rounded">
      <Row className="justify-content-center">
        {images.length > 0 ? (
          <>
            {/* Control panel images */}
            <Row className="p-2 rounded-top bg-dark align-items-center">
              {selectedImages.length > 0 && (
                <>
                  {/* Download */}
                  <Col className="d-flex justify-content-start">
                    <Button
                      variant="secondary"
                      className="m-1"
                      onClick={onDownloadSelected}
                    >
                      <AiOutlineDownload className="me-1" /> Download{" "}
                      {selectedImages.length}
                    </Button>

                    {/* Delete  */}
                    {show_delete && (
                      <Button
                        className="m-1"
                        variant="danger"
                        onClick={onDeleteSelected}
                      >
                        <AiOutlineDelete className="me-1" /> Delete{" "}
                        {selectedImages.length}
                      </Button>
                    )}
                  </Col>
                </>
              )}
              <Col className="d-flex justify-content-end align-items-center">
                {selectedImages.length < images.length && (
                  <>
                    {/* select all */}
                    <Button
                      className="m-1"
                      variant="light"
                      onClick={() => setSelectedImages(images)}
                    >
                      Select All
                    </Button>
                  </>
                )}

                {selectedImages.length > 0 && (
                  <>
                    {/* select all */}
                    <Button
                      className="m-1"
                      variant="light"
                      onClick={() => setSelectedImages([])}
                    >
                      Unselect All
                    </Button>
                  </>
                )}
              </Col>
            </Row>
            <Row className="p-1"></Row>
            <div className="bg-dark p-2 rounded-bottom">
              <div className={styles.galleryContainer}>
                {" "}
                {/* Apply the container style */}
                <Gallery
                  images={IMAGES}
                  enableImageSelection={true}
                  onClick={handleImageClick}
                  onSelect={handleImageSelect}
                  rowHeight={getDynamicRowHeight()}
                  margin={5}
                  defaultContainerWidth={Math.min(containerWidth, 1200)} // Cap width for larger screens
                />
                ;
              </div>
            </div>
            {open && (
              <Lightbox
                open={open}
                close={() => setOpen(false)}
                slides={images.map((image) => ({
                  src: img_url(image.name),
                }))}
                index={selectedIndex}
                onIndexChange={setSelectedIndex}
              />
            )}
          </>
        ) : (
          <div className="text-center">
            <p>No images available</p>
          </div>
        )}
      </Row>
    </div>
  );
};

export default ImageGallery;
