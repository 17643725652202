// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Gallery.module.css */

.Gallery_galleryContainer__IDKb\\+ {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
    margin: 20px auto;
    padding: 10px;
    border-radius: 10px;
  }
  
  .Gallery_react-grid-gallery__eeO4T img {
    padding: 10px;
    margin: 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
    object-fit: cover;
  }

  body {
    margin: 0;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .Gallery_custom-overlay__caption__yhCYk {
    background-color: rgba(0, 0, 0, 0.8);
    max-height: 240px;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    width: 100%;
    color: white;
    padding: 2px;
    font-size: 90%;
  }
  
  .Gallery_custom-overlay__tag__Nedb9 {
    word-wrap: break-word;
    display: inline-block;
    background-color: white;
    height: auto;
    font-size: 75%;
    font-weight: 600;
    line-height: 1;
    padding: .2em .6em .3em;
    border-radius: .25em;
    color: black;
    vertical-align: baseline;
    margin: 2px;
  }`, "",{"version":3,"sources":["webpack://./src/components/style/Gallery.module.css"],"names":[],"mappings":"AAAA,uBAAuB;;AAEvB;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,SAAS;IACT,iBAAiB;IACjB,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,WAAW;IACX,sBAAsB;IACtB,kBAAkB;IAClB,iBAAiB;EACnB;;EAEA;IACE,SAAS;IACT,wEAAwE;IACxE,eAAe;IACf,mCAAmC;IACnC,kCAAkC;EACpC;;EAEA;IACE,oCAAoC;IACpC,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,YAAY;IACZ,YAAY;IACZ,cAAc;EAChB;;EAEA;IACE,qBAAqB;IACrB,qBAAqB;IACrB,uBAAuB;IACvB,YAAY;IACZ,cAAc;IACd,gBAAgB;IAChB,cAAc;IACd,uBAAuB;IACvB,oBAAoB;IACpB,YAAY;IACZ,wBAAwB;IACxB,WAAW;EACb","sourcesContent":["/* Gallery.module.css */\n\n.galleryContainer {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-wrap: wrap;\n    gap: 15px;\n    margin: 20px auto;\n    padding: 10px;\n    border-radius: 10px;\n  }\n  \n  .react-grid-gallery img {\n    padding: 10px;\n    margin: 5px;\n    border: 1px solid #ddd;\n    border-radius: 5px;\n    object-fit: cover;\n  }\n\n  body {\n    margin: 0;\n    font-family: \"Open Sans\", \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n    font-size: 16px;\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n  }\n  \n  .custom-overlay__caption {\n    background-color: rgba(0, 0, 0, 0.8);\n    max-height: 240px;\n    overflow: hidden;\n    position: absolute;\n    bottom: 0;\n    width: 100%;\n    color: white;\n    padding: 2px;\n    font-size: 90%;\n  }\n  \n  .custom-overlay__tag {\n    word-wrap: break-word;\n    display: inline-block;\n    background-color: white;\n    height: auto;\n    font-size: 75%;\n    font-weight: 600;\n    line-height: 1;\n    padding: .2em .6em .3em;\n    border-radius: .25em;\n    color: black;\n    vertical-align: baseline;\n    margin: 2px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"galleryContainer": `Gallery_galleryContainer__IDKb+`,
	"react-grid-gallery": `Gallery_react-grid-gallery__eeO4T`,
	"custom-overlay__caption": `Gallery_custom-overlay__caption__yhCYk`,
	"custom-overlay__tag": `Gallery_custom-overlay__tag__Nedb9`
};
export default ___CSS_LOADER_EXPORT___;
