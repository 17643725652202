import React, { useRef, useState  } from 'react';
import { isMobile, isAndroid, isIOS, isMacOs, isWindows } from 'react-device-detect';


function CameraOrGalleryInput() {
  const videoRef = useRef(null);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);
  const fileInputRef = useRef(null);


  const openCamera = async () => {
    setIsCameraOpen(true);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      videoRef.current.srcObject = stream;
      videoRef.current.play();
    } catch (error) {
      console.error("Camera access denied or unavailable:", error);
      alert("Unable to access the camera.");
    }
  };

  const captureImage = () => {
    const video = videoRef.current;
    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const context = canvas.getContext('2d');
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    setCapturedImage(canvas.toDataURL('image/png')); // Save captured image
  };

  const stopCamera = () => {
    const stream = videoRef.current.srcObject;
    const tracks = stream.getTracks();
    tracks.forEach((track) => track.stop());
    setIsCameraOpen(false);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => setCapturedImage(reader.result); // Display the selected image
      reader.readAsDataURL(file);
    }
  };


  const openFilePicker = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Programmatically trigger the file input
    }
  };


  return (
    <div>
     <div>
      <h3>Device Information</h3>
      {isMobile && <p>You're using a mobile device</p>}
      {isAndroid && <p>Operating System: Android</p>}
      {isIOS && <p>Operating System: iOS</p>}
      {isMacOs && <p>Operating System: macOS</p>}
      {isWindows && <p>Operating System: Windows</p>}
      {!isMobile && !isAndroid && !isIOS && !isMacOs && !isWindows && (
        <p>Operating System: Unknown</p>
      )}
    </div>
    
      {!isCameraOpen ? (
        <div>
          <button onClick={openCamera}>Open Camera</button>
          <button type="button" onClick={openFilePicker}>
        Upload from Gallery
      </button>
      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        style={{ display: 'none' }} // Keep it hidden
        onChange={handleFileChange}
      />
        </div>
      ) : (
        <div>
          <video ref={videoRef} style={{ width: '100%' }}></video>
          <button onClick={captureImage}>Capture Photo</button>
          <button onClick={stopCamera}>Close Camera</button>
        </div>
      )}

      {capturedImage && (
        <div>
          <h3>Captured Image:</h3>
          <img src={capturedImage} alt="Captured" style={{ width: '100%' }} />
        </div>
      )}
    </div>
  );
}

export default CameraOrGalleryInput;
