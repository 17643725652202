// src/components/CameraCapture.js
import React, { useRef, useState } from "react";
import Webcam from "react-webcam";
import axios from "axios";
import { isMobile, isAndroid, isIOS, isMacOs, isWindows } from 'react-device-detect';

// Import FontAwesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";


import { Button, Container, Row, Image, Spinner } from "react-bootstrap";


const CameraCapture = ({ event_id, setMatchGallery, setShowMatch , setNbImagesAnalysed}) => {
  const webcamRef = useRef(null);
  const fileInputRef = useRef(null);
  const [image, setImage] = useState(null);
  const [isCameraOpen, setIsCameraOpen] = useState(false); // State to control camera visibility
  const [isUploading, setIsUploading] = useState(false);
  const videoConstraints = {
    width: { ideal: 1260 }, // Set ideal width
    height: { ideal: 720 }, // Set ideal height
    facingMode: "user", // Use the front camera
  };

  const openFilePicker = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Programmatically trigger the file input
    }
  }


  const handleShowMatchGallery = (images) => {
    if (images) {
      setMatchGallery(images);
      setShowMatch(true);
      setImage(null);
    } else {
      setMatchGallery([]);
      setShowMatch(false);
    }
  };

  // Open the camera when 'Scan' button is clicked
  const openCamera = () => {

    setIsCameraOpen(true);

    // Reset the image state
    setImage(null);
    // Reset the match state
    handleShowMatchGallery(null);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => setImage(reader.result); // Display the selected image
      reader.readAsDataURL(file);
    }
  };

  const closeCamera = () => {
    setIsCameraOpen(false);
  };

  // Capture the image
  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImage(imageSrc);
    closeCamera();
  };

  // Upload the image to the ML endpoint
  const getImageMatch = async () => {
    setIsUploading(true);

    // Convert image to a Blob
    const blob = await fetch(image).then((res) => res.blob());
    handleShowMatchGallery(null);

    // Create FormData for the image file
    const formData = new FormData();
    formData.append("picture", blob, "captured_image.png");

    try {
      /** retrieve the event id from http://localhost:3000/1ASDF3 */
      const response = await axios.post(
        process.env.REACT_APP_MODEL_ENDPOINT + `/get_match?eid=${event_id}`, // Use the environment variable here
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      // console.log('Response:', response.data);
      // alert('Image uploaded successfully!');
      handleShowMatchGallery(response.data.images);
      setNbImagesAnalysed(response.data.nb_analysed);

    } catch (error) {
      // {"error":"no directory found"}
      console.error("Error uploading image:", error);
      // show the error data when exist
      if (error.response && error.response.data) {  
        alert(error.response.data.error);
      } else {
        alert("Failed to upload the image.");
      }
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div style={{ textAlign: "center" }}>
      <h4>Find your photos!</h4>
      <Container>
        {/* Show the camera when the "Scan" button is clicked */}
        {isCameraOpen && (
          <div>
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              width={420}
              height={240}
              videoConstraints={videoConstraints}
            />
            <div style={{ margin: "20px 0" }}>
              {/* Capture button with Camera Icon */}
              <Button onClick={capture} style={styles.cameraButton}>
                <FontAwesomeIcon icon={faCamera} size="2x" />
              </Button>
            </div>
          </div>
        )}
      </Container>

      <Container className="">
        <div className="m-2">
          {/* Scan button to open the camera */}
          {!isCameraOpen && (
            <>
              <div>
                <Button onClick={openFilePicker}>1. Post a Selfie</Button>
                <input
                  ref={fileInputRef}
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }} // Keep it hidden
                  onChange={handleFileChange}
                />
                {!isMobile && <> Or <Button onClick={openCamera}>Open Camera</Button></>}
              </div>
            </>
          )}
        </div>
        <div>
          {/* Show preview and the 'Show the magie' button */}
          {image && (
            <>
              <Image
                thumbnail
                src={image}
                alt="Captured"
                style={{ width: 420 }}
              />
              <div className="m-2">
                {isUploading ? (
                  <>
                    {/* Boostrap spinner */}
                    <Button variant="secondary" disabled>
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span className="m-2">Searching a match...</span>
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      variant="success"
                      onClick={getImageMatch}
                      disabled={isUploading}
                    >
                      2. Show the magie
                    </Button>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </Container>
    </div>
  );
};

// Inline style for camera button (optional)
const styles = {
  cameraButton: {
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    padding: "10px",
    borderRadius: "50%",
    cursor: "pointer",
    outline: "none",
  },
};

export default CameraCapture;
