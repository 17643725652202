import CameraCapture from "./../components/CameraCapture";
import ImageGallery from "./../components/ImageGallery";
import CameraOrGalleryInput from "./../components/CameraOrGalleryInput";
import Header from "./../components/Header";
import { useParams } from "react-router-dom";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";

const UserPanel = ({ event }) => {
  const [images, setImages] = useState([]);
  // TODO: Add a state to store the number of images analyzed
  const [nbimagesAnalysed, setNbImagesAnalysed] = useState(null);
  const [showMatch, setShowMatch] = useState(false);

  // Handle image capture (add to gallery)
  const handleMatch = (newImage) => {
    // reset the images array with the news images array
    setImages(newImage);
  };

  // const handleShowMatch = (showMatch) => {
  //   setShowMatch(showMatch);
  // }

  // Handle image click (for preview, etc.)
  const handleImageClick = (image) => {
    alert(`Clicked on image: ${image}`);
    // You can expand this function to open a modal or larger preview
  };

  // Handle image deletion
  const handleDelete = (index) => {
    const newImages = images.filter((_, i) => i !== index);
    setImages(newImages);
  };

  return (
    /* Show the event name */
    event && (
      <>
        {/* Camera capture component */}
        <CameraCapture
          event_id={event.id}
          setMatchGallery={handleMatch}
          setShowMatch={setShowMatch}
          setNbImagesAnalysed={setNbImagesAnalysed}
        />

        {/* <CameraOrGalleryInput /> */}

        

        {/* show gallery is images found, else show now images found */}
        {showMatch &&
          (images.length > 0 ? (
            /* Image Gallery component */
            <>
              <h4 className="text-center mt-5">
                {images.length} Images Matched
                {nbimagesAnalysed != null && (
                  <small className="text-center m-2 text-secondary">
                    ({nbimagesAnalysed} images analyzed)
                  </small>
                )}
              </h4>

              <ImageGallery
                event_id={event.id}
                images={images}
                setImages={setImages}
              />
            </>
          ) : nbimagesAnalysed > 0 ? (
            <>
              <div className="text-center">
                <h4 className="text-center mt-5">
                  No Match
                  {nbimagesAnalysed != null && (
                    <small className="text-center m-2 text-secondary">
                      ({nbimagesAnalysed} images analyzed)
                    </small>
                  )}
                </h4>
              </div>
            </>
          ) : (
            <div className="text-center">
              <h4 className="text-center mt-5">Any photo to analyse.</h4>
            </div>
          ))}
      </>
    )
  );
};
export default UserPanel;
